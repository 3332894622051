import React from 'react';
import {useField, useFormikContext} from 'formik';
import {Form} from 'react-bootstrap';
import {StyledCheckLabel} from '@styled';
import {unset} from 'lodash';
import styled, {keyframes} from 'styled-components';

const popIn = keyframes`
from {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.5) ;
}
to {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1) ;
}
`;

const StyledCheck = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: 0em;
`;

const StyledCheckInput = styled(Form.Check.Input)`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Indicator = styled.div`
  border: 1px solid;
  border-radius: 1em;
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  top: 1px;
  left: 0em;

  ${StyledCheck}:hover & {
    background: #ccc;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  ${StyledCheckInput}:checked + &::after {
    display: block;
    border: solid #263238;
    border-radius: 1em;
    background-color: #263238;
    width: 0.5em;
    height: 0.5em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${popIn};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  ${StyledCheckInput}:disabled + & {
    pointer-events: none;
    opacity: 0.6;
    background: #e6e6e6;
  }
`;

const RadioField = ({
  label,
  id,
  disabled,
  required,
  field,
  meta,
  onChange,
  className = '',
  value: fieldValue,
}) => {
  const isInvalid = meta.error ? true : false;
  return (
    <Form.Group className={className}>
      <StyledCheck htmlFor={id}>
        {label && <StyledCheckLabel>{label}</StyledCheckLabel>}
        {/* {label && required && <StyledRequiredLabel>*</StyledRequiredLabel>} */}
        <StyledCheckInput
          id={id}
          disabled={disabled}
          type="radio"
          role="radio"
          {...field}
          checked={field.value === fieldValue ? true : false}
          value={field.value}
          isInvalid={isInvalid}
          onChange={onChange}
        />
        <Indicator />
        {isInvalid && required ? (
          <Form.Control.Feedback type="invalid">
            {meta.error || ''}
          </Form.Control.Feedback>
        ) : null}
      </StyledCheck>
    </Form.Group>
  );
};

const RadioController = (props) => {
  const [field, meta, helpers] = useField(props);
  const {errors, setErrors} = useFormikContext();
  const onChange = (e) => {
    helpers.setValue(props.value);
    const tmpErrors = errors;
    unset(tmpErrors, field.name);
    setErrors(tmpErrors);
    if (props.onChange) props.onChange(e);
  };
  return () => RadioField({...props, field, meta, onChange});
};

const Radio = (props) => RadioController(props)();
export default Radio;
