import React from 'react';
import {useField, useFormikContext} from 'formik';
import {Form} from 'react-bootstrap';
import {StyledCheckLabel, StyledRequiredLabel} from '@styled';
import {unset} from 'lodash';
import styled, {keyframes} from 'styled-components';

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

const StyledCheck = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: 0em;
`;

const StyledCheckInput = styled(Form.Check.Input)`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Indicator = styled.div`
  width: 1.2em;
  height: 1.2em;
  background: #e6e6e6;
  position: absolute;
  top: 2px;
  left: 0em;
  border: 1px solid #757575;
  border-radius: 0.2em;

  ${StyledCheckInput}[class*="is-invalid"] & {
    border: 1px solid #dc3545;
  }

  ${StyledCheckInput}:not(:disabled):checked & {
    background: #d1d1d1;
  }

  ${StyledCheck}:hover & {
    background: #ccc;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  ${StyledCheckInput}:checked + &::after {
    display: block;
    top: 0.1em;
    left: 0.35em;
    width: 35%;
    height: 70%;
    border: solid #263238;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;

const CheckBox = ({
  label,
  id,
  disabled,
  required,
  field,
  meta,
  fieldValue,
  type = 'checkbox',
  onChange,
}) => {
  const isInvalid = meta.error ? true : false;
  return (
    <Form.Group>
      <StyledCheck htmlFor={id}>
        {label && <StyledCheckLabel>{label}</StyledCheckLabel>}
        {label && required && <StyledRequiredLabel>*</StyledRequiredLabel>}
        <StyledCheckInput
          id={id}
          required={required}
          disabled={disabled}
          type={type}
          checked={field.value.includes(fieldValue)}
          {...field}
          value={fieldValue}
          isInvalid={isInvalid}
          onChange={onChange}
        />
        <Indicator />
        {isInvalid && (
          <Form.Control.Feedback type="invalid">
            {meta.error || ''}
          </Form.Control.Feedback>
        )}
      </StyledCheck>
    </Form.Group>
  );
};

const CheckboxController = (props) => {
  const [field, meta] = useField(props);
  const {errors, setErrors} = useFormikContext();
  const onChange = (e) => {
    field.onChange(e);
    const tmpErrors = errors;
    unset(tmpErrors, field.name);
    setErrors(tmpErrors);
    if (props.onChange) props.onChange(e);
  };
  return () => CheckBox({...props, field, meta, onChange});
};

const CheckboxField = (props) => CheckboxController(props)();
export default CheckboxField;
